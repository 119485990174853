/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #666;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9em;

  p {
    margin: 0 0 5px 0;
  }

  a {
    color: #777;
  }

  .social a {
    border: 0;
  }

  img {
    display: inline-block;
    margin-bottom: 5px;
  }
}
.form-select.is-invalid:not([multiple]):not([size]) {
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;

  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 680px;

    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: 100%;
      max-width: 150px;
      height: auto;
    }

    span.big,
    span.small {
      display: inline;
      font-size: 1.3em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }

    span.big {
      font-weight: 600;
    }

    span.small {
      display: none;
      margin-top: 3px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;

    p {
      float: right;
      margin: 2px 12px 0 0;

      strong {
        font-size: 16px;
      }
    }

    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }

      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }

    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }

    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#banner-wrap {
  background: #000 url(../images/banner-bg-sm.jpg) no-repeat;
  background-size: cover;

  h1 {
    br {
      display: none;
    }
  }

  &.tabc {
    background: #000 url(../images/banner-bg-tabc-sm.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  &.food-handler {
    background: #000 url(../images/banner-bg-food-sm.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  &.food-manager {
    background: #000 url(../images/banner-bg-food2-sm.jpg) no-repeat;
    background-size: cover;
  }

  &.large {
    &.corporate-accounts {
      background: transparent url('../images/bulk-discounts-banner-mobile.jpg') no-repeat 50% 50% / cover;
    }
  }
}

#products-wrap {
  br {
    display: none;
  }
}

.alt-1 {
  background: $paleblue;
}

.btn-price {
  color: $lightblue;
}

.bg-theme {
  background: $theme-primary;
  color: #fff;
}

.accordion .btn.focus,
.accordion .btn:focus {
  box-shadow: 0 0 0 0;
}

span.slashprice {
  font-size: 16px;
  color: #fc4a1a;
  font-weight: 400;
  margin-left: 2px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: -8%;
    top: 47%;
    width: 116%;
    height: 1px;
    background-color: #fc4a1a;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

#howitworks {
  h3 {
    &.icon-box {
      margin-top: 0;
    }

    span.blue {
      font-size: 0.8em;
      color: $theme-primary;
    }

    img {
      display: block;
      margin: 10px auto 15px auto;
    }
  }

  .icon-box {
    .icon {
      display: inline-block;
      margin-bottom: 10px;
      width: 80px;
      height: 80px;
      background: url('../images/how-it-works-icons.png') no-repeat 0 0 / cover;

      &.certificate {
        background-position: -80px 0;
      }

      &.approved {
        background-position: -160px 0;
      }

      &.support {
        background-position: -240px 0;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: 300;
      color: $theme-primary;
      font-size: 1.266em;
    }

    h3 + p {
      margin-top: 0;
    }

    p {
      line-height: 1.4em;
    }

    br {
      display: none;
    }
  }
}

a.contact-icon {
  border: 0;
  color: #888;
  font-weight: 600;

  i {
    font-size: 1.2em;
    border: 2px solid #ccc;
  }

  &:hover {
    color: inherit;
  }
}

.fw-bold-light {
  font-weight: 600 !important;
}

.alert-light-med {
  background-color: #f2f2f2;
  border-color: #fdfdfe;
}

a.badge {
  border: 0;
  &.badge-info-hover:hover {
    background-color: darken(#4aa4d8, 7.5%) !important;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }

  #header a.logo {
    img {
      display: block;
      float: left;
    }

    span.big {
      display: inline-block;
      font-size: 1.5em;
    }

    span.small {
      display: inline-block;
    }
  }

  #banner-wrap {
    background: #000 url(../images/banner-bg.jpg) no-repeat 75% 50%;
    background-size: cover;

    h1 {
      br {
        display: block;
      }
    }

    &.tabc {
      background: #000 url(../images/banner-bg-tabc.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    &.food-handler {
      background: #000 url(../images/banner-bg-food.jpg) no-repeat;
      background-size: cover;
    }

    &.food-manager {
      background: #000 url(../images/banner-bg-food2.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    #banner {
      p {
        font-size: 1.2em;
      }
    }
  }

  #products-wrap {
    margin-top: 20px;

    .card.callout-card {
      margin-top: -34px;
      .header-link {
        border-bottom: 1px dotted #fff;
        color: #fff;
        &:hover {
          border-bottom: 1px solid #fff;
        }
      }
    }

    br {
      display: block;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header a.logo {
    width: 70%;
  }

  #header-wrap {
    border-top: 3px solid $theme-primary;

    #header #support {
      display: block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 65%;
  }

  #banner-wrap {
    &.large {
      .details {
        max-width: 530px;
      }
      &.corporate-accounts {
        background: transparent url('../images/bulk-discounts-banner.jpg') no-repeat 50% 50% / cover;
      }
    }
  }
}

// Extra large devices (large desktops, 1080px and up)
@media (min-width: 1080px) {
  #banner-wrap.home {
    padding-bottom: 70px;
  }

  #products-wrap {
    margin-top: -65px;

    .card {
      border: 0;
      border-bottom: 1px solid #ddd;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrap {
    #header #support {
      display: block;

      span.phone {
        display: inline-block;
      }
    }
  }

  #howitworks {
    .icon-box {
      br {
        display: block;
      }
    }
  }
}
